<template>
  <v-container fluid>
    <v-dialog
      :value="true"
      persistent
      max-width="600"
      transition="dialog-transition"
      overlay-color="black"
      overlay-opacity="0.1"
      @input="$emit('close-dialog')"
    >
      <v-card>
        <v-form style="min-height: 550px;" @submit.prevent="update">
          <v-card-title style="min-height: 120px;">
            <v-list-item class="d-flex flex-row justify-space-between">
              <v-row class="d-flex flex-row justify-start align-center">
                <span>{{ $t('titles.dataOf', [$t('common.player')] ) }}</span>
              </v-row>
              <v-list-item-action>
                <v-btn icon color="primary" @click="$emit('close-dialog')">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-main class="px-6 py-0">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col class="py-1 px-3" cols="12" sm="6">
                    <div class="mb-3 d-flex">
                      <h2 class="mr-3" v-text="$t('user.fields.avatar')" />
                      <global-tooltip :text="$t('common.tooltipImageSizes', { proportion: '1:1', width: '400px', height: '400px'})" />
                    </div>
                    <image-field v-model="form.avatar" :width="400" :height="400" can-be-deleted suffix="_avatar" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.firstName"
                  :error-messages="getFieldErrors('firstName')"
                  :label="$t('user.fields.firstName')"
                  outlined
                  dense
                  @blur="$v.form.firstName.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.lastName"
                  :error-messages="getFieldErrors('lastName')"
                  :label="$t('user.fields.lastName')"
                  outlined
                  dense
                  @blur="$v.form.lastName.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.dni"
                  :error-messages="getFieldErrors('dni')"
                  :label="$t('user.fields.numberIdentification') + ' ' + $t('common.optional')"
                  outlined
                  dense
                  @blur="$v.form.dni.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <birthday-field
                  v-model="form.birthdate"
                  :error-messages="getFieldErrors('birthdate')"
                  :label="$t('user.fields.birthdate') + ' ' + $t('common.optional')"
                  @blur="$v.form.birthdate.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model.number="form.number"
                  :label="$t('user.fields.numberPlayer')"
                  type="number"
                  :min="0"
                  outlined
                  dense
                  :error-messages="getFieldErrors('number')"
                  @keypress="validateKeyNumeric($event)"
                  @blur="$v.form.number.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.licenseCode"
                  :error-messages="getFieldErrors('licenseCode')"
                  :label="`${$t('user.fields.licenseCode')}  ${$t('common.optional')}`"
                  outlined
                  dense
                  @blur="$v.form.licenseCode.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.parentEmail"
                  :error-messages="getFieldErrors('parentEmail')" :label="`${$t('user.fields.parentEmail')}  ${$t('common.optional')}`"
                  type="email" name="email" autocomplete="email" outlined dense @blur="$v.form.parentEmail.$touch()"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.email"
                  :error-messages="getFieldErrors('email')" :label="`${$t('user.fields.playerEmail')}  ${$t('common.optional')}`"
                  type="email" name="email" autocomplete="email" outlined dense @blur="$v.form.email.$touch()"
                />
              </v-col>
            </v-row>
          </v-main>
          <v-card-actions class="d-flex flex-row justify-center align-center ma-0">
            <v-btn
              color="primary"
              width="10rem"
              type="submit"
              depressed
              v-text="$t('common.save')"
            />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, integer, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'
import { strToId } from '@/utils/formatters'

export default {
  name: 'PlayerFormDialog',
  components: {
    BirthdayField: () => import('@/components/formFields/BirthdayField'),
    ImageField: () => import('@/components/formFields/ImageField'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils],
  props: {
    player: {
      type: Object,
      default: null,
    },
    emailsPlayers: { type: Array, required: true },
    parentEmailsPlayers: { type: Array, required: true },
    numbersPlayers: { type: Array, required: true },
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters({ players: 'club/players' }),
    isEditing() {
      return !!this.player
    },
  },
  validations() {
    return {
      form: {
        avatar: {},
        firstName: { required },
        lastName: { required },
        dni: { },
        birthdate: { },
        number: { integer, required, isUnique: value => !this.numbersPlayers.filter(n => n !== this.player?.number)?.includes(value) },
        licenseCode: {},
        parentEmail: {
          email,
          isUnique: value => !this.emailsPlayers.includes(value),
        },
        email: {
          email,
          isUnique: value => !this.emailsPlayers.filter(e => e !== this.player?.email).includes(value)
          && !this.parentEmailsPlayers.filter(e => e !== this.player?.parentEmail).includes(value),
        },
      },
    }
  },

  created() {
    this.form = {
      ...this.player,
      birthdate: this.isEditing && this.player.birthdate && this.player.birthdate.year ? `${this.player.birthdate?.year}-${this.player.birthdate?.month}-${this.player.birthdate?.day}` : null,
    }
  },

  methods: {
    parseBirthdate() {
      if (this.form.birthdate) {
        const [year, month, day] = this.form.birthdate.split('-')
        return {
          year: +year,
          month: +month,
          day: +day,
        }
      }
      return null
    },
    parseForm() {
      return {
        ...this.form,
        number: Number(this.form.number),
        birthdate: this.parseBirthdate(),
      }
    },
    update() {
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      this.$emit(this.isEditing ? 'update' : 'create', this.parseForm())
      this.$emit('close-dialog')
    },
  },
}
</script>
